
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { translateApi } from '../utils/translate-api';
import { UsePageHeaderMixin } from '../mixins/use-page-header.mixin';
import { StaticContentType } from '../enums/static-content-type.enum';
import { StaticContent } from '../models/static-contents/static-content.interface';
import { Privacy as PrivacyModel } from '../models/static-contents/privacy.interface';

const staticContentsModule = namespace('staticContents');

@Component({
  components: {},
  filters: {
    translateApi,
  },
})
export default class Privacy extends Mixins(UsePageHeaderMixin) {
  @staticContentsModule.Action('fetchStaticContent')
  fetchStaticContent!: (type: StaticContentType) => Promise<StaticContent>;

  @staticContentsModule.Getter('getStaticContentByType')
  getStaticContentByType!: (type: StaticContentType) => PrivacyModel;

  privacy: PrivacyModel | null = null;

  get privacyContent() {
    return translateApi(this.privacy?.content?.value, this.$i18n.locale);
  }

  get headerHtml() {
    if (this.privacy) {
      return `<h2 class="text-white">${translateApi(
        this.privacy.title.value,
        this.$i18n.locale,
      )}</h2>`;
    } else return '';
  }

  async created() {
    if (!this.getStaticContentByType(StaticContentType.Privacy)) {
      await this.fetchStaticContent(StaticContentType.Privacy);
    }
    this.privacy = this.getStaticContentByType(StaticContentType.Privacy);
  }
}
